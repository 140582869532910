import React from 'react';
import './BasicScreenVital.css';

const BasicScreenVital = ({ basicScreenData }) => {
    console.log('basicScreenData:', basicScreenData);

    // Assuming basicScreenData contains a property named 'birthDefect' with values 'NAD' or other values
    const isGood = basicScreenData && basicScreenData.birthDefect === 'NAD';

    return (
        <div>
            <h6 className="basic">Basic Screening</h6>
            <div className={`elementbasicscreen ${isGood ? 'good' : 'bad'}`}></div>

            <div className="row">
                <div className="col-md-6">
                    <h6 className="basicccc">Birth Defect:</h6>
                </div>
                <div className="col-md-5 mt-2">
                    {isGood ? <p>No</p> : <p>Yes</p>}
                </div>
            </div>
        </div>
    );
}

export default BasicScreenVital;
